import "../scss/styles.scss";
import { CommentToggler } from "./comment-toggler";
import { PostDetail } from "./post-detail";
import { buildCheckboxListener, buildCommentTableDeleteListener } from "./lib";
import * as bootstrap from "bootstrap";

const ready = (callback) => {
  if (document.readyState != "loading") callback();
  else document.addEventListener("DOMContentLoaded", callback);
};

const route = () => {
  // Get the current active django url
  const activeUrl = JSON.parse(
    document.querySelector("#active_url").textContent,
  );
  console.log("activeUrl", activeUrl);

  // Load API urls
  const apiUrls = JSON.parse(document.querySelector("#api_urls").textContent);
  console.log("apiUrls", apiUrls);

  switch (activeUrl) {
    case "post_detail":
      new PostDetail(apiUrls);
      break;
  }
};

const enableTooltips = () => {
  const tooltipTriggerList = document.querySelectorAll(
    '[data-bs-toggle="tooltip"]',
  );
  const tooltipList = [...tooltipTriggerList].map(
    (tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl),
  );
};

ready(() => {
  buildCheckboxListener();
  buildCommentTableDeleteListener();
  new CommentToggler();
  enableTooltips();
  route();
});
