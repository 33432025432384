import { Collapse } from "bootstrap";

export class CommentToggler {
  constructor() {
    const query = document.querySelectorAll(".toggle-comments");
    [...query].map((elem) =>
      elem.addEventListener("click", (source) => {
        this.toggleComment(source.target.dataset.proId, true);
        return true;
      }),
    );
  }

  toggleComment(proId, isParent = true, hiding = true) {
    if (proId == "65853145") {
      const content = document
        .querySelector(`#comment-body-${proId}`)
        .textContent.trim();

      console.log(
        `Comment ${proId}: parent = ${isParent}, hiding = ${hiding}:`,
        content,
      );
    }

    // If this is the parent, hide only the body of the comment, otherwise hide the whole comment
    const selector = isParent ? `#comment-body-${proId}` : `#comment-${proId}`;
    const collapseElem = new Collapse(selector, { toggle: false });

    const commentBodyDisplayed = document
      .querySelector(`#comment-body-${proId}`)
      .classList.contains("show");

    if (proId == "65853145") {
      console.log(`Comment ${proId}: hiding`, hiding);
    }

    if (isParent) {
      if (commentBodyDisplayed) {
        collapseElem.hide();
        hiding = true;
      } else {
        collapseElem.show();
        hiding = false;
      }
    } else {
      if (hiding) {
        collapseElem.hide();
        if (proId == "65853145") {
          console.log(`Hiding ${isParent ? "parent" : "body"}`);
        }
      } else {
        collapseElem.show();
        if (proId == "65853145") {
          console.log(`Showing ${isParent ? "parent" : "body"}`);
        }
      }
    }

    document
      .querySelectorAll(`div[data-parent-pro-id='${proId}']`)
      .forEach((element) => {
        const childId = element.dataset.proId;
        this.toggleComment(childId, false, hiding);
      });
  }
}
