export const buildCheckboxListener = () => {
  if (!document.getElementById("check-all-comments")) {
    return;
  }
  document
    .querySelector("#check-all-comments")
    .addEventListener("click", (source) => {
      const checkboxes = document.querySelectorAll(".comment-checkbox");
      checkboxes.forEach(
        (checkbox) => (checkbox.checked = source.target.checked),
      );
    });
};

export const buildCommentTableDeleteListener = () => {
  if (!document.getElementById("comment-table-delete")) {
    return;
  }

  document.getElementById("comment-table-delete").onclick = (event) => {
    let accepted = confirm("Are you sure you want to save?");
    if (!accepted) {
      event.stopPropagation();
    }
    return accepted;
  };
};
