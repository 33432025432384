export class PostDetail {
  constructor(apiUrls) {
    this.apiUrls = apiUrls;

    this.onUpdatePost();
  }

  onUpdatePost() {
    const btn = document.querySelector("#update-post-btn");
    btn.addEventListener("click", (event) => {
      btn.disabled = true;
      btn.classList.add("d-none");
      document.querySelector("#updating").classList.remove("d-none");
      this.updatePost(btn.dataset.proId);
    });
  }

  updatePost(proId) {
    const csrfToken = document.querySelector(
      "[name=csrfmiddlewaretoken]",
    ).value;
    const request = new Request(this.apiUrls["update_post"], {
      method: "POST",
      headers: {
        "X-CSRFToken": csrfToken,
        "content-type": "application/json",
      },
      mode: "same-origin", // Do not send CSRF token to another domain.
      body: JSON.stringify({
        pro_id: proId,
      }),
    });
    fetch(request).then((response) => {
      console.log(response);
      location.reload();
    });
  }
}
